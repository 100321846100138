<template>
  <div class="drag-fruits-game-container layout-border">
    <!-- <audio :src="pinyinAudioSrc" ref="audioBox"></audio> -->
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <Star :totalStarNumber="gameList.length" :currentIndex="starNum" />
      <div class="drag-area">
        <img
          class="drag-item"
          v-for="(item, index) in gameList[currentIndex].optionList"
          :key="index"
          :src="item.image"
          draggable="true"
          @dragstart="startDrag($event, item)"
          @drop.stop="Sdrop($event, index)"
          @dragend="endDrag()"
          :class="{ notShow: item.id === dragId, notDisplay: item.isNotShow }"
        />
      </div>

      <div class="right-box">
        <div class="right-content">
          <div
            class="drop-area"
            @drop.stop="dropToImg($event)"
            @dragover.stop="allowDrop($event)"
          />
          <div class="sentence-area">
            <p class="pinyin font-pinyin-medium">
              {{ gameList[currentIndex].pinyin }}
            </p>
            <p class="font-hanzi-medium">{{ gameList[currentIndex].hanzi }}</p>
          </div>
          <div class="correct-img">
            <img
              :src="gameList[currentIndex].correctImg"
              :class="{ showImg: isShowCorrectImg }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import Star from "@/components/Course/GamePage/Star";

export default {
  name: "DragFruitsGame",
  components: {
    PageButton,
    Star,
  },
  props: {
    gameList: {
      type: Array,
      require: true,
    },
    hasAudio: {
      type: Boolean,
      require: false,
      default: true,
    },
  },
  data() {
    return {
      isShowCorrectImg: false,
      isCorrect: false,
      currentIndex: 0,
      isLastStep: false,
      starNum: 0,
      dragId: null,
      dropToImgSocketInfo: {},
      dragImgStrokeSocketInfo: {},
      audioSocketInfo: {},
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },

        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  watch: {
    dropToImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2006201,
          data: { value },
          text: "DragFruitsGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    dragImgStrokeSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2006202,
          data: { value },
          text: "DragFruitsGame drag socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("dropFruitsStrokeEvent", () => {
      this.dropToImg("socket", true);
    });
    this.$bus.$on("dragToFruitsImgEvent", ({ item }) => {
      this.startDrag("socket", item, true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("dropFruitsStrokeEvent");
    this.$bus.$off("dragToFruitsImgEvent");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    startDrag(event, item, isFromSocket = false) {
      if (!isFromSocket) {
        this.dragImgStrokeSocketInfo = {
          item,
          value: Math.random(),
        };
      }
      this.dragId = item.id;
      this.isCorrect = item.isCorrect;
    },
    dropToImg(event, isFromSocket = false) {
      if (!isFromSocket) {
        this.dropToImgSocketInfo = {
          value: Math.random(),
        };
      }
      // event.preventDefault();
      if (this.isCorrect) {
        playCorrectSound(true, false);
        this.isShowCorrectImg = true;

        setTimeout(() => {
          this.isShowCorrectImg = false;
          this.starNum++;
          this.currentIndex++;
          this.dragId = null;
          if (this.currentIndex === this.gameList.length) {
            startConfetti();
            playCorrectSound();
            this.isLastStep = true;
            this.currentIndex = this.gameList.length - 1;
            this.isShowCorrectImg = true;
          }
        }, 1500);
      } else {
        playCorrectSound(false);
      }
    },
    endDrag() {
      this.dragId = null;
    },
    Sdrop(event) {
      event.preventDefault();
    },
    allowDrop(event) {
      event.preventDefault();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
}
.objectDisplay {
  position: absolute;
  width: 50%;
  bottom: 16%;
  left: 25%;
}
.plate {
  bottom: 10%;
  position: absolute;
  width: 60%;
  left: 20%;
}

.drag-fruits-game-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    background-image: url("../../../../assets/img/16-GAMES/G22-drag-abc/background.svg");
    border-radius: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    .drag-area {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      // width: 15%;
      margin-right: 5%;
      background-image: url("../../../../assets/img/16-GAMES/G22-drag-abc/shape.svg");
      background-size: contain;
      background-repeat: no-repeat;
      .drag-item {
        width: 85%;
        -webkit-user-drag: auto;
        z-index: 5;
        cursor: pointer;
        margin-right: 5%;
      }
      .notShow {
        opacity: 0;
      }
      .notDisplay {
        visibility: hidden;
      }
    }
    .right-box {
      height: 70%;
      background: #f7f4e0;
      border-radius: 25px;
      width: 45%;
      border-bottom: 10px solid #b5392c;
      .right-content {
        width: 100%;
        height: 100%;
        border-radius: 10%;
        display: flex;
        flex-direction: column;
        position: relative;

        .drop-area {
          // background: #000;
          position: absolute;
          width: 71%;
          height: 55%;
          top: 32%;
          left: 16%;
          z-index: 6;
        }
        .sentence-area {
          width: 100%;
          flex: 1;
          background: #224e96;
          text-align: center;
          color: #fff;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
        }
        .correct-img {
          width: 100%;
          flex: 2;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0;
          }
          .showImg {
            opacity: 1;
          }
        }
      }
    }
  }
}

img {
  &.animatedstar {
    display: block;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    -webkit-animation-iteration-count: 1;
  }
}
</style>
