var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "drag-fruits-game-container layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content" },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.gameList.length,
              currentIndex: _vm.starNum,
            },
          }),
          _c(
            "div",
            { staticClass: "drag-area" },
            _vm._l(
              _vm.gameList[_vm.currentIndex].optionList,
              function (item, index) {
                return _c("img", {
                  key: index,
                  staticClass: "drag-item",
                  class: {
                    notShow: item.id === _vm.dragId,
                    notDisplay: item.isNotShow,
                  },
                  attrs: { src: item.image, draggable: "true" },
                  on: {
                    dragstart: function ($event) {
                      return _vm.startDrag($event, item)
                    },
                    drop: function ($event) {
                      $event.stopPropagation()
                      return _vm.Sdrop($event, index)
                    },
                    dragend: function ($event) {
                      return _vm.endDrag()
                    },
                  },
                })
              }
            ),
            0
          ),
          _c("div", { staticClass: "right-box" }, [
            _c("div", { staticClass: "right-content" }, [
              _c("div", {
                staticClass: "drop-area",
                on: {
                  drop: function ($event) {
                    $event.stopPropagation()
                    return _vm.dropToImg($event)
                  },
                  dragover: function ($event) {
                    $event.stopPropagation()
                    return _vm.allowDrop($event)
                  },
                },
              }),
              _c("div", { staticClass: "sentence-area" }, [
                _c("p", { staticClass: "pinyin font-pinyin-medium" }, [
                  _vm._v(
                    " " + _vm._s(_vm.gameList[_vm.currentIndex].pinyin) + " "
                  ),
                ]),
                _c("p", { staticClass: "font-hanzi-medium" }, [
                  _vm._v(_vm._s(_vm.gameList[_vm.currentIndex].hanzi)),
                ]),
              ]),
              _c("div", { staticClass: "correct-img" }, [
                _c("img", {
                  class: { showImg: _vm.isShowCorrectImg },
                  attrs: { src: _vm.gameList[_vm.currentIndex].correctImg },
                }),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }